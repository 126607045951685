import React from 'react';
import { JoykitColor } from '@components/themes';
import { JoykitIcon, JoykitIconVariant } from '@components/JoykitIcon';
import { PhotoCreditLink, PhotoCreditWrap, TextContent } from './PhotoCredit.styles';
import { JoykitIconSize } from '@components/themes/createTheme.util';

interface BasePhotoCreditProps {
  id: string;
  leftIconVariant?: boolean;
  creditTo?: string | React.ReactElement[]; // allow html parser values
  icon?: JoykitIconVariant;
  iconSize?: JoykitIconSize;
  color?: JoykitColor;
  hoverColor?: JoykitColor;
  activeColor?: JoykitColor;
  style?: React.CSSProperties;
  offset?: 'top-left' | 'top-right' | 'bottom-left';
  position?: 'absolute' | 'relative';
  instaHandleLink?: string;
}
export type PhotoCreditProps = BasePhotoCreditProps;

export const PhotoCredit: React.FC<PhotoCreditProps> = ({
  id,
  position,
  offset,
  creditTo,
  icon,
  iconSize,
  leftIconVariant,
  style,
  color,
  hoverColor,
  activeColor,
  instaHandleLink,
  ...restProps
}) => {
  const offsets = () => {
    switch (offset) {
      case 'top-left':
        return { top: 0, left: 0 };
      case 'top-right':
        return { top: 0, right: 0 };
      case 'bottom-left':
        return { bottom: 0, left: 0 };
      default:
        return { bottom: 0, right: 0 };
    }
  };
  const defaultStyle = { position: position || 'absolute', ...offsets(), ...(style || {}) };
  return (
    <PhotoCreditWrap {...restProps} flexDirection={leftIconVariant ? 'row' : 'row-reverse'} alignItems="center" style={defaultStyle}>
      <PhotoCreditLink
        id={id}
        href={instaHandleLink || 'https://www.jlbwedding.com/'}
        leftIconVariant={leftIconVariant}
        hoverColor={hoverColor}
        activeColor={activeColor}
      >
        {icon && (
          <JoykitIcon
            name={icon}
            color={color || 'purple1'}
            size={iconSize || 16}
            marginLeft={leftIconVariant ? '' : [3]}
            marginRight={leftIconVariant ? [3] : ''}
          />
        )}
        <TextContent variant="dek20" tagName="span" color={color}>
          {creditTo || '@JLBwedding'}
        </TextContent>
      </PhotoCreditLink>
    </PhotoCreditWrap>
  );
};
